import pkg from '../package.json';

export default async ({ sparkyFramework: { getFrameworkVars } }) => {
  console.info(
    `%c${pkg.name}: ${pkg.version}`,
    'font-size: 14px; color: white; background-color: black; border-radius: 8px; padding: 10px 15px;',
  );

  const { private_site_origin } = getFrameworkVars();
  return async () => {
    return {
      actions: [
        {
          // This can be accessed only by route - see hidden attribute below
          key: 'netsec-co-pilot-conversation',
          path: '/netsec-copilot/conversation',
          title: 'NetSec CoPilot chat',
          contentClassName: 'sparky-light-only panwds-tw3', // make it light theme only
          bypassTsgAvail: true, // allow user do not have any role to access this path
          bypassGlobalAvail: true, // allow user without any tsg access to this path
          noRBACheck: true,
          avail: () => true,
          exact: false,
          //contentClassName: 'panwds-tw3 sparky-dark-only',
          component: () =>
            import('../src/components/SingleConversationWrapper'),
          availFallbackRedirect: 'no-redirect',
          hidden: true, // Hide Nav item
        },
        {
          // sample URL
          //tse-assistant?caseNumber=02982902&tsgTenantId=1665278339&productType=ngfw&accountName=test&cdlTenantId=1977327585&reportTs=1714159341870
          key: 'tse-assistant',
          path: '/tse-assistant',
          title: 'TSE Assistant',
          icon: 'tse-assistant-icon',
          className: 'tse-assistant-page',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          exact: false,
          avail: () => Boolean(private_site_origin), //control the show and hide of tse assistant only on AIOps for gcs env,
          callAvail: true,
          component: () => import('../src/pages/TSEAssistant'),
          availFallbackRedirect: 'no-redirect',
        },
        {
          key: 'embedded_copilot_csp',
          path: '/embedded/copilot/csp',
          contentClassName: 'sparky-light-only panwds-tw3 copilot-csp-page', // make it light theme only
          bypassTsgAvail: true, // allow user do not have any role to access this path
          bypassGlobalAvail: true, // allow user without any tsg access to this path
          hidden: true, // not show on left nav
          title: 'CSP CoPilot',
          noRBACheck: true,
          expandable: true,
          mergeNav: true,
          exact: false,
          component: () => import('../src/pages/CSPHostApp'),
          availFallbackRedirect: 'no-redirect',
        },
      ],
      exposes: {
        default: {
          CopilotIconButton: () => import('./components/CopilotButtonIcon'),
        },
        'scm_main_layout.content_right': {
          CopilotContextual: () => import('./pages/HostApp'),
        },
      },
    };
  };
};
